import { fetchWrapper } from '@/helpers';

const ruta = process.env.VUE_APP_ROOT_API;

export default class SucursalService {

  async getSucursalesAll() {
    const sucursales = await fetchWrapper.get(`${ruta}/sucursal`);
    return sucursales;
  }

  async sendSucursalNueva(sucursal) {
    const nuevaSucursal = await fetchWrapper.post(`${ruta}/sucursal`, sucursal);
    return nuevaSucursal;
  }
  async deleteSucursal(sucursal) {
    const sucursalEliminada = await fetchWrapper.delete(`${ruta}/sucursal/` + sucursal.id);
    return sucursalEliminada;
  }
  async updatedSucursal(sucursal) {
    const sucursalActualizada = await fetchWrapper.put(`${ruta}/sucursal/` + sucursal.id, sucursal);
    return sucursalActualizada;
  }
  async showSucursal(sucursal) {
    const sucursalShow = await fetchWrapper.get(`${ruta}/sucursal/` + sucursal);
    return sucursalShow;
  }
  updateImpresion(datos) {
    return fetchWrapper.post(`${ruta}/update_impresion`, datos);
  }

  async uploadImgQR(sucursal_id, img) {
    const imgQR = await fetchWrapper.postFiles(`${ruta}/sucursal_qr_img/` + sucursal_id, img);
    return imgQR;
  }
}
